#wallpaper-container {
    width: 60%;
    height: 100vh;
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) )
}

#loginform {
    width: 420px;
}

#login-paragraphs p {
    color: #646C9A;
}

h4 {
    color: #595D6E;
}

a:hover {
    color: #B90064;
}

input[type="text"], input[type="password"] {
    border-radius: 10px !important;
}

#footer-text, a {
    color: #595D6E;
}

/*Only display the BETE wallpaper on large screens*/
@media only screen and (max-width: 1200px) {
    #wallpaper-container {display: none;}
}

button {
    background-color: #B90064 !important;
    border-radius: 10px !important;
}

button:hover {
    background-color: #002856 !important;
}
